import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import packageJson from '../../../../package.json';

// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { profileId, role, roleDisplay, userName } = useSelector((state) => state.userReducer);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 210,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, alignItems: 'center' }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', margin: 'auto' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search
            <SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <Stack alignItems="center" sx={{ marginLeft: 2 }}>
                <Typography variant="h3" color="secondary">
                    SGE Portal
                </Typography>
                <Typography variant="caption">Version {packageJson.version}</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2} alignItems="center">
                <Stack>
                    <Typography variant="h5">
                        Hello, {role == 0 && 'Dr.'} {userName}
                    </Typography>
                    <Typography variant="caption">{roleDisplay}</Typography>
                </Stack>
                <ProfileSection />
            </Stack>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;

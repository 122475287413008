// material-ui
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const { profileId, role } = useSelector((state) => state.userReducer);
    const theme = useTheme();
    return <img src={logo} alt="Logo" width="100" />;
};

export default Logo;

import { Button, Icon } from '@mui/material';
import React from 'react';

const ContainedButton = ({ title, icon: IconComponent, color, onClickFunction, sx, disabled }) => {
    return (
        <>
            <Button
                variant="contained"
                sx={sx}
                color={color != undefined ? color : 'primary'}
                onClick={onClickFunction}
                startIcon={IconComponent ? <IconComponent fontSize="inherit" /> : null}
                disabled={disabled}
            >
                {title}
            </Button>
        </>
    );
};

export default ContainedButton;

import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';

import Dashboard from '../views/dashboard/index.jsx';
import MaterialReceipt from 'views/general-sales-invoice/MaterialReceipt.jsx';

// login routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

// master routing
const CustomerCategory = Loadable(lazy(() => import('views/masters/customer-category-master/index.jsx')));
const Customer = Loadable(lazy(() => import('views/masters/customer/index.jsx')));
const VendorCategory = Loadable(lazy(() => import('views/masters/vendor-category-master/index.jsx')));
const Vendor = Loadable(lazy(() => import('views/masters/vendor/index.jsx')));
const ProductCategory = Loadable(lazy(() => import('views/masters/product-category/index.jsx')));
const Product = Loadable(lazy(() => import('views/masters/product/index.jsx')));
const PartCategory = Loadable(lazy(() => import('views/masters/part-category-master/index.jsx')));
const PartList = Loadable(lazy(() => import('views/masters/part-master/index.jsx')));
const User = Loadable(lazy(() => import('views/masters/user/index.jsx')));
const Account = Loadable(lazy(() => import('views/masters/account/index.jsx')));
const CompanyList = Loadable(lazy(() => import('views/masters/company/index.jsx')));
const ExpenseCaterogy = Loadable(lazy(() => import('views/masters/expense-category-master/index.jsx')));
const Service = Loadable(lazy(() => import('views/masters/service-master')));
const Location = Loadable(lazy(() => import('views/masters/location-master/index.jsx')));

//invoice import

//const PurchaseRecord = Loadable(lazy(() => import('views/purchase-record')));

//Inquiry
const SalesInquiry = Loadable(lazy(() => import('views/inquiries/sales-inquiry/index.jsx')));
const InquiryDetails = Loadable(lazy(() => import('views/inquiries/sales-inquiry/inquiry-details/index.jsx')));
const SalesInquiryForm = Loadable(lazy(() => import('views/inquiries/sales-inquiry/SalesInquiryForm.jsx')));
const MaintenanceInquiry = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/index.jsx')));
const MaintenanceInquiryForm = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/MaintenanceInquiryForm.jsx')));
const MaintenanceInquiryDetials = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/index.jsx')));
const AdvanceToEmployee = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/advance-to-employee/index.jsx'))
);
const AdvanceReturnByEmployee = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/advance-return-by-employee/index.jsx'))
);
const TaskData = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/task/index.jsx')));
const TaskDetails = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/task/TaskDetails/index.jsx'))
);
const PartInwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/part-inward-record/index.jsx'))
);
const PartOutwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/part-outword-record/index.jsx'))
);
const MachineInwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-inward-record/index.jsx'))
);
const MachineInwardRecordForm = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-inward-record/MachineInwardRecordForm.jsx'))
);
const MachineOutwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-outward-record/index.jsx'))
);
const MachineOutwardRecordForm = Loadable(
    lazy(() =>
        import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-outward-record/MachineOutwardRecordForm.jsx')
    )
);
const Advance = Loadable(lazy(() => import('views/advance/index.jsx')));

//Purchase Records

const PurchaseRecords = Loadable(lazy(() => import('views/purchase-records/index')));
const PurchaseRecordForm = Loadable(lazy(() => import('views/purchase-records/PurchaseRecordForm')));

//Quotation
const Quotation = Loadable(lazy(() => import('views/quotation/index')));
const QuotationForm = Loadable(lazy(() => import('views/quotation/QuotationForm')));

//Sales Invoice
const SalesInvoice = Loadable(lazy(() => import('views/general-sales-invoice/index')));
const SalesInvoiceForm = Loadable(lazy(() => import('views/general-sales-invoice/GeneralSalesInvoiceForm')));
// //Service Invoice
// const ServiceInvoice = Loadable(lazy(() => import('views/service-invoice/index.jsx')));
// const ServiceInvoiceForm = Loadable(lazy(() => import('views/service-invoice/ServiceInvoiceForm.jsx')));
//Expenses
const Expenses = Loadable(lazy(() => import('views/expenses/index.jsx')));
//Customer Payment
const CustomerPayment = Loadable(lazy(() => import('views/customer-payment/index.jsx')));
//Vendor Payment
const VendorPayment = Loadable(lazy(() => import('views/vendor-payment/index.jsx')));
//Store
const StoreData = Loadable(lazy(() => import('views/store/index.jsx')));

//Reports
const Reports = Loadable(lazy(() => import('views/reports/index.jsx')));
//Attendance
const Attendance = Loadable(lazy(() => import('views/attendance/index.jsx')));

//Transfer Records
const TransferRecords = Loadable(lazy(() => import('views/transfer-records/index.jsx')));
const TransferRecordForm = Loadable(lazy(() => import('views/transfer-records/TransferRecordForm.jsx')));

//Complaints
const Complaints = Loadable(lazy(() => import('views/complaints/index.jsx')));
const ComplaintsForm = Loadable(lazy(() => import('views/complaints/ComplaintsForm.jsx')));
//My Complaints
const MyComplaints = Loadable(lazy(() => import('views/my-complaints/index.jsx')));
const MyComplaintsForm = Loadable(lazy(() => import('views/my-complaints/MyComplaintsForm.jsx')));
//Complaints Bills
const ComplaintBills = Loadable(lazy(() => import('views/complaint-bills/index.jsx')));
const ComplaintBillForm = Loadable(lazy(() => import('views/complaint-bills/ComplaintBillForm.jsx')));
//Complaints History
const ComplaintHistory = Loadable(lazy(() => import('views/complaint-history/index.jsx')));

//Purchase Record Return
const PurchaseRecordReturn = Loadable(lazy(() => import('views/purchase-record-return/index.jsx')));
const PurchaseRecordReturnForm = Loadable(lazy(() => import('views/purchase-record-return/PurchaseRecordReturnForm.jsx')));

//Sales Return
const SalesReturn = Loadable(lazy(() => import('views/sales-return/index.jsx')));
const SalesReturnForm = Loadable(lazy(() => import('views/sales-return/SalesReturnForm.jsx')));

//Performa Invoices
const PerformaInvoice = Loadable(lazy(() => import('views/performa-invoice/index.jsx')));
const PerformaInvoiceForm = Loadable(lazy(() => import('views/performa-invoice/PerformaInvoiceForm.jsx')));

//Store Transaction
const StoreTransaction = Loadable(lazy(() => import('views/store-transaction/index.jsx')));
const StoreTransactionRecordForm = Loadable(lazy(() => import('views/store-transaction/StoreTransactionRecordForm.jsx')));
//Store Transaction
const ProductHistory = Loadable(lazy(() => import('views/history/product-history/index.jsx')));
const PartHistory = Loadable(lazy(() => import('views/history/part-history/index.jsx')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole, locationId } = useSelector((state) => state.userReducer);
    //const userId = 1;
    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: role == 1 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/dashboard',
                element: role == 1 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/customer',
                element: role == 1 || role == 2 ? <Customer /> : <Navigate to="/login" />
            },
            {
                path: '/product',
                element: role == 1 ? <Product /> : <Navigate to="/login" />
            },

            {
                path: '/product-category',
                element: role == 1 ? <ProductCategory /> : <Navigate to="/login" />
            },
            {
                path: '/vendor-category-master',
                element: role == 1 ? <VendorCategory /> : <Navigate to="/login" />
            },
            {
                path: '/vendor',
                element: role == 1 ? <Vendor /> : <Navigate to="/login" />
            },
            {
                path: '/employees',
                element: role == 1 ? <User /> : <Navigate to="/login" />
            },
            {
                path: '/company',
                element: role == 1 ? <CompanyList /> : <Navigate to="/login" />
            },
            {
                path: '/account',
                element: role == 1 ? <Account /> : <Navigate to="/login" />
            },
            {
                path: '/expenses-category-master',
                element: role == 1 || role == 2 ? <ExpenseCaterogy /> : <Navigate to="/login" />
            },
            {
                path: '/customer-category-master',
                element: role == 1 || role == 2 ? <CustomerCategory /> : <Navigate to="/login" />
            },
            {
                path: '/part-master',
                element: role == 1 ? <PartList /> : <Navigate to="/login" />
            },
            {
                path: '/part-category-master',
                element: role == 1 ? <PartCategory /> : <Navigate to="/login" />
            },
            {
                path: '/service',
                element: role == 1 ? <Service /> : <Navigate to="/login" />
            },
            {
                path: '/location-master',
                element: role == 1 ? <Location /> : <Navigate to="/login" />
            },

            {
                path: '/sales-inquiry',
                element: userId > 0 ? <SalesInquiry /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry',
                element: userId > 0 ? <MaintenanceInquiry /> : <Navigate to="/login" />
            },
            {
                path: '/inquiry-details',
                element: userId > 0 ? <InquiryDetails /> : <Navigate to="/login" />
            },
            {
                path: '/sales-inquiry-form',
                element: userId > 0 ? <SalesInquiryForm /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry-form',
                element: userId > 0 ? <MaintenanceInquiryForm /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry-details',
                element: userId > 0 ? <MaintenanceInquiryDetials /> : <Navigate to="/login" />
            },
            {
                path: '/task',
                element: userId > 0 ? <TaskData /> : <Navigate to="/login" />
            },
            {
                path: '/task-details',
                element: userId > 0 ? <TaskDetails /> : <Navigate to="/login" />
            },
            {
                path: '/part-inward-record',
                element: userId > 0 ? <PartInwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/part-outward-record',
                element: userId > 0 ? <PartOutwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-inward-record',
                element: userId > 0 ? <MachineInwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-inward-record-form',
                element: userId > 0 ? <MachineInwardRecordForm /> : <Navigate to="/login" />
            },
            {
                path: '/machine-outward-record',
                element: userId > 0 ? <MachineOutwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-outward-record-form',
                element: userId > 0 ? <MachineOutwardRecordForm /> : <Navigate to="/login" />
            },
            {
                path: '/advance-to-employee',
                element: userId > 0 ? <AdvanceToEmployee /> : <Navigate to="/login" />
            },
            {
                path: '/advance-return-by-employee',
                element: userId > 0 ? <AdvanceReturnByEmployee /> : <Navigate to="/login" />
            },
            {
                path: '/advance',
                element: userId > 0 ? <Advance /> : <Navigate to="/login" />
            },
            // ------------------------PurchaseRecords------------------------------
            {
                path: '/purchase-records',
                element: userId > 0 ? <PurchaseRecords /> : <Navigate to="/login" />
            },
            {
                path: '/purchase-record/form',
                element: userId > 0 ? <PurchaseRecordForm /> : <Navigate to="/login" />
            },
            // ------------------------Quotations------------------------------
            {
                path: '/quotation',
                element: userId > 0 ? <Quotation /> : <Navigate to="/login" />
            },
            {
                path: '/quotation/form',
                element: userId > 0 ? <QuotationForm /> : <Navigate to="/login" />
            },
            // ------------------------General Sales Invoice------------------------------
            {
                path: '/sales-invoice',
                element: userId > 0 ? <SalesInvoice /> : <Navigate to="/login" />
            },
            {
                path: '/material-receipt',
                element: userId > 0 && locationId == 3 ? <MaterialReceipt /> : <Navigate to="/login" />
            },
            {
                path: '/sales-invoice/form',
                element: userId > 0 ? <SalesInvoiceForm /> : <Navigate to="/login" />
            },
            // ------------------------Service Invoice------------------------------
            // {
            //     path: '/service-invoice',
            //     element: userId > 0 ? <ServiceInvoice /> : <Navigate to="/login" />
            // },
            // {
            //     path: '/service-invoice-form',
            //     element: userId > 0 ? <ServiceInvoiceForm /> : <Navigate to="/login" />
            // },
            // ------------------------Expenses------------------------------
            {
                path: '/expenses',
                element: userId > 0 || role == 2 ? <Expenses /> : <Navigate to="/login" />
            },
            // ------------------------Customer Payment------------------------------
            {
                path: '/customer-payment',
                element: userId > 0 ? <CustomerPayment /> : <Navigate to="/login" />
            },
            // ------------------------Vendor Payment------------------------------
            {
                path: '/vendor-payment',
                element: userId > 0 ? <VendorPayment /> : <Navigate to="/login" />
            },
            // ------------------------Material Store------------------------------
            {
                path: '/store',
                element: userId > 0 ? <StoreData /> : <Navigate to="/login" />
            },
            // ------------------------Reports------------------------------
            {
                path: '/reports',
                element: userId > 0 ? <Reports /> : <Navigate to="/login" />
            },
            // ------------------------Attendance------------------------------
            {
                path: '/attendance',
                element: userId > 0 ? <Attendance /> : <Navigate to="/login" />
            },
            // ------------------------Store Transaction------------------------------
            {
                path: '/store-transaction',
                element: userId > 0 ? <StoreTransaction /> : <Navigate to="/login" />
            },
            {
                path: '/store-transaction/form',
                element: userId > 0 ? <StoreTransactionRecordForm /> : <Navigate to="/login" />
            },
            // ------------------------Transfer Record------------------------------
            {
                path: '/transfer-records',
                element: userId > 0 ? <TransferRecords /> : <Navigate to="/login" />
            },
            {
                path: '/transfer-record/form',
                element: userId > 0 ? <TransferRecordForm /> : <Navigate to="/login" />
            },
            //-------------------------Complaints-----------------------------------
            {
                path: '/complaints',
                element: userId > 0 ? <Complaints /> : <Navigate to="/login" />
            },
            {
                path: '/complaints/form',
                element: userId > 0 ? <ComplaintsForm /> : <Navigate to="/login" />
            },
            //-------------------------My Complaints-----------------------------------
            {
                path: '/my-complaints',
                element: userId > 0 ? <MyComplaints /> : <Navigate to="/login" />
            },
            {
                path: '/my-complaints/form',
                element: userId > 0 ? <MyComplaintsForm /> : <Navigate to="/login" />
            },
            //-------------------------Complaints Bills-----------------------------------
            {
                path: '/complaint-bills',
                element: userId > 0 ? <ComplaintBills /> : <Navigate to="/login" />
            },
            {
                path: '/complaint-bills/form',
                element: userId > 0 ? <ComplaintBillForm /> : <Navigate to="/login" />
            },
            //-------------------------Complaints History-----------------------------------
            {
                path: '/complaint-history',
                element: userId > 0 ? <ComplaintHistory /> : <Navigate to="/login" />
            },
            //-------------------------Purchase Record Return-----------------------------------
            {
                path: '/purchase-record-return',
                element: userId > 0 ? <PurchaseRecordReturn /> : <Navigate to="/login" />
            },
            {
                path: '/purchase-record-return/form',
                element: userId > 0 ? <PurchaseRecordReturnForm /> : <Navigate to="/login" />
            },
            //-------------------------Sales Return-----------------------------------
            {
                path: '/sales-return',
                element: userId > 0 ? <SalesReturn /> : <Navigate to="/login" />
            },
            {
                path: '/sales-return/form',
                element: userId > 0 ? <SalesReturnForm /> : <Navigate to="/login" />
            },
            //-------------------------Proforma Invoice-----------------------------------
            {
                path: '/performa-invoice',
                element: userId > 0 ? <PerformaInvoice /> : <Navigate to="/login" />
            },
            {
                path: '/performa-invoice/form',
                element: userId > 0 ? <PerformaInvoiceForm /> : <Navigate to="/login" />
            },
            //-------------------------History-----------------------------------
            {
                path: '/product-history',
                element: userId > 0 ? <ProductHistory /> : <Navigate to="/login" />
            },
            {
                path: '/part-history',
                element: userId > 0 ? <PartHistory /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}

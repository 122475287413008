//React Imports
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
//MUI Imports
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';

//Component Imports
import ToolTipButton from 'ui-component/Button/ToolTipButton';

//Icon Imports
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { convertDateToLocal } from 'utils/date-operations';
import { Stack } from '@mui/system';

//Project Imports

const GeneralSalesInvoiceTable = ({ data, handleDelete, type }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { role } = useSelector((state) => state.userReducer);
    const tableHeading = useMemo(
        () => [
            { title: '#', width: 50 },
            { title: 'Invoice No.', width: 130 },
            { title: 'Date', width: 130 },
            { title: 'Location', width: 100 },
            { title: 'Customer PO No.' },
            { title: 'Customer' },
            { title: 'IsGST', width: 150 },
            { title: 'Amount', width: 150, align: 'right' },
            { width: 140, align: 'right' }
        ],
        []
    );

    return (
        <>
            <TableContainer
                sx={{
                    border: 'solid',
                    borderWidth: 1,
                    borderColor: 'divider'
                }}
            >
                <Table size="small">
                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                        <TableRow>
                            {tableHeading.map((row, index) => (
                                <>
                                    <TableCell
                                        key={index}
                                        width={row.width != undefined ? row.width : null}
                                        align={row.align != undefined ? row.align : 'left'}
                                    >
                                        <Typography variant="h5">{row.title}</Typography>
                                    </TableCell>
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : theme.palette.grey[50]
                                }}
                            >
                                <TableCell sx={{ width: 40 }}>{index + 1}</TableCell>
                                <TableCell>
                                    SGE/{String(item.fyStart).slice(2, 4)}
                                    {String(item.fyEnd).slice(2, 4)}
                                    {String(item.number).padStart(5, '0')}
                                </TableCell>
                                <TableCell>{convertDateToLocal(item.date)}</TableCell>
                                <TableCell>
                                    {item.locationId == 1 && 'Miraj-Main'}
                                    {item.locationId == 2 && 'Miraj'}
                                    {item.locationId == 3 && 'Athani'}
                                </TableCell>
                                <TableCell>
                                    {item.customerPONumber != null && item.customerPONumber != '' ? item.customerPONumber : '--'}
                                </TableCell>
                                <TableCell>
                                    {item.customer?.name}, {item.customer?.city}
                                </TableCell>
                                <TableCell>{item.isGST == true ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="right">{item.invoiceAmount?.toFixed(2)}</TableCell>

                                <TableCell align="right">
                                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                                        <ToolTipButton
                                            title="Edit Sales Invoice"
                                            disabled={!(role === 1 || role == 2 || isToday(item.date))}
                                            onClickFunction={() => {
                                                let state = { type: type, customerType: item.customerId == 2 ? 0 : 1 };
                                                if (role === 1 || role == 2 || isToday(item.date)) {
                                                    navigate('/sales-invoice/form?id=' + item.id, { state: state });
                                                } else {
                                                }
                                            }}
                                            icon={EditIcon}
                                        />
                                        {item.isAccepted ? (
                                            <>
                                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0.5}>
                                                    <Typography variant="caption" color="success.dark">
                                                        Accepted
                                                    </Typography>
                                                    <CheckCircleOutlinedIcon color="success" fontSize="small" />
                                                </Stack>
                                            </>
                                        ) : (
                                            <>
                                                <ToolTipButton
                                                    title="Delete Sales Invoice"
                                                    color="error"
                                                    disabled={!(role === 1 || isToday(item.date))}
                                                    onClickFunction={() => {
                                                        if (role === 1 || isToday(item.date)) {
                                                            handleDelete(item);
                                                        } else {
                                                        }
                                                    }}
                                                    icon={DeleteIcon}
                                                />
                                            </>
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
const isToday = (date) => {
    const today = new Date();
    const recordDate = new Date(date);
    return (
        today.getDate() === recordDate.getDate() &&
        today.getMonth() === recordDate.getMonth() &&
        today.getFullYear() === recordDate.getFullYear()
    );
};
export default GeneralSalesInvoiceTable;

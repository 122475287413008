import useAxiosAuth from 'hooks/useAxiosAuth';

import { handleResponse, handleError } from './response';

const useGeneralSalesInvoiceService = () => {
    const axiosAuth = useAxiosAuth();
    const getSalesInvoiceFilteredList = async (text, fromDate, toDate, customerId, locationId, type, skip, take) => {
        try {
            const response = await axiosAuth.get(
                `/api/SalesInvoice/filtered-list?searchText=${text}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}&locationId=${locationId}&type=${type}&skip=${skip}&take=${take}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getAllSalesInvoiceList = async () => {
        try {
            const response = await axiosAuth.get(`/api/SalesInvoice`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesInvoiceById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/SalesInvoice/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesInvoiceBySerialNumber = async (serialNumber, partId, productId) => {
        try {
            const response = await axiosAuth.get(
                `/api/SalesInvoice/by-serialnumber?serialnumber=${serialNumber}&partId=${partId}&productId=${productId}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addSalesInvoice = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/SalesInvoice`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateSalesInvoice = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SalesInvoice?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteSalesInvoice = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/SalesInvoice?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getSalesInvoiceFilteredList,
        getAllSalesInvoiceList,
        getSalesInvoiceBySerialNumber,
        getSalesInvoiceById,
        addSalesInvoice,
        updateSalesInvoice,
        deleteSalesInvoice
    };
};

export default useGeneralSalesInvoiceService;

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 240;
export const appDrawerWidth = 320;
export const borderRadius = 3;

export const taskStatus = ['Assigned', 'Ongoing', 'Completed', 'Cancelled'];
export const taskType = ['Onsite', 'Offsite'];
export const gstType = [' Unregistered', 'Registered - Regular', 'Registered- Composition', ' Consumer'];
export const productStatus = ['Active', 'InActive'];
export const partStatus = ['Active', 'InActive'];
export const userRole = ['Owner', 'Admin', 'Sales Executive', 'Office Staff', 'Service Engineer', 'Customer', 'Accounts'];
export const userType = ['Internal', 'External'];
export const quotationType = [
    { value: 0, title: 'New' },
    { value: 1, title: 'Revised' }
];
export const materialStatus = ['Assigned', 'Handoverd', ' Used', ' Returned', 'Damaged', 'Lost'];
export const customerPaymentMode = ['Cash', 'UPI', 'Credit/Debit Card', 'Bank Transfer', 'Cheque'];
export const UserStatus = ['Active', 'InActive'];
export const expenseLocation = ['Miraj', 'Athani'];
export const maintenanceWarrantyType = ['In Warranty', 'Out Of Warranty'];
//Customer Master Status
export const customerType = ['Customer', 'Dealer'];
//Attendance Status
export const attendanceType = ['Full Day', 'Half Day', 'Absent', 'On Leave'];
//Store Transaction Status
export const storeTransactionStatus = ['Allotment Positive', 'Allotment Negative', 'Return Positive', 'Return Negative', 'Damaged', 'Lost'];

//Attendance Report Month
export const attendanceMonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
//Attendance Report year
export const attendanceYear = [2021, 2022, 2023, 2024, 2025, 2026];

export const complaintType = ['In Warranty', 'Out Of Warranty'];

export const complaintStatus = [
    { value: 1, title: 'Inward' },
    { value: 3, title: 'Ready To Deliver' },
    { value: 4, title: 'Invoice Generated' },
    { value: 5, title: 'Deliverd' }
];

export const storeStatus = [
    { value: 1, title: 'Alloted' },
    { value: 2, title: 'Returned' }
];
export const complaintBillStatus = [
    { value: 3, title: 'Ready To Deliver' },
    { value: 5, title: 'Deliverd' }
];

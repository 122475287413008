import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

const ToolTipButton = ({ title, color, onClickFunction, icon: IconComponent, disabled, ...rest }) => {
    return (
        <>
            <Tooltip title={title}>
                <IconButton
                    {...rest}
                    disabled={disabled}
                    variant="outlined"
                    color={color != undefined ? color : 'primary'}
                    size="small"
                    onClick={onClickFunction}
                >
                    <IconComponent fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default ToolTipButton;

import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SkeletonComponent = ({ count }) => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
        skeletons.push(
            <Stack spacing={1}>
                <Skeleton key={i} variant="rounded" height={60} sx={{ marginY: 1 }} />
            </Stack>
        );
    }

    return <>{skeletons}</>;
};

export default SkeletonComponent;

export function handleResponse(response) {
    console.log('response123', response);
    return response;
}

export function handleError(error) {
    console.log('error123', error.response);
    if (error.response) {
        return error.response;
    }
    return error;
}

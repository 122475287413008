import { Stack, Typography } from '@mui/material';
import React from 'react';
import NoResults from 'assets/images/noresults.png';

// ***Method to use***
// <NoDataFound />

const NoDataFound = ({ title }) => {
    return (
        <>
            <Stack justifyContent="center" alignItems="center" spacing={2}>
                <img src={NoResults} alt="No Result" width="150px" />
                <Typography variant="h4" fontWeight={400}>
                    There are no details found
                </Typography>
            </Stack>
        </>
    );
};

export default NoDataFound;

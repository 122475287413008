//React Imports
import React from 'react';
import { useNavigate } from 'react-router';
//MUI Imports
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';

//Icon Imports
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//Component Imports
import ToolTipButton from 'ui-component/Button/ToolTipButton';
import { convertDateToLocal } from 'utils/date-operations';

const GeneralSalesInvoiceCard = ({ data, handleEdit, handleDelete, type }) => {
    const navigate = useNavigate();
    return (
        <>
            {' '}
            {data?.map((row, index) => (
                <Grid key={index} sx={{ mt: 2 }}>
                    <Card sx={{ p: 2 }} variant="outlined" key={index}>
                        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
                            <Typography variant="h5">Invoice No.:</Typography>
                            <Typography>
                                {String(row.fyStart).slice(2, 4)}
                                {String(row.fyEnd).slice(2, 4)}
                                {String(row.number).padStart(5, '0')}
                            </Typography>
                        </Stack>
                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                            <Typography variant="h5">Customer PO:</Typography>
                            <Typography>{row.customerPONumber}</Typography>
                        </Stack>
                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                            <Typography variant="h5">Customer:</Typography>
                            <Typography>
                                {row.customer?.name}, {row.customer?.city}
                            </Typography>
                        </Stack>
                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                            <Typography variant="h5">Date:</Typography>
                            <Typography>{convertDateToLocal(row.date)}</Typography>
                        </Stack>
                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                            <Typography variant="h5">Final Amount:</Typography>
                            <Typography>{row.finalAmount}</Typography>
                        </Stack>

                        <Divider sx={{ mt: 1 }} />
                        <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                            {row.isAccepted ? (
                                <></>
                            ) : (
                                <>
                                    <ToolTipButton
                                        title="Edit Sales Invoice Details"
                                        onClickFunction={() => {
                                            let state = { type: type, customerType: row.customerId == 2 ? 0 : 1 };
                                            navigate('/sales-invoice/form?id=' + row.id, { state: state });
                                        }}
                                        icon={EditIcon}
                                    />
                                    <ToolTipButton
                                        title="Delete Sales Invoice "
                                        color="error"
                                        onClickFunction={() => handleDelete(row)}
                                        icon={DeleteIcon}
                                    />
                                </>
                            )}
                        </Stack>
                    </Card>
                </Grid>
            ))}
        </>
    );
};

export default GeneralSalesInvoiceCard;

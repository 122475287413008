//React Imports
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useReducer, useSelector } from 'react-redux';

//MUI Imports
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    useTheme
} from '@mui/material';

//Icon Imports

import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

//Component Imports
import ContainedButton from 'ui-component/Button/ContainedButton';
import NoDataFound from 'ui-component/NoData/NoDataFound';
import Progress from 'ui-component/Progress/Progress';
import DeleteDialog from 'ui-component/dialog/DeleteDialog';

import LoadingButtonComponent from 'ui-component/Button/LoadingButtonComponent';

//Project Imports

import GeneralSalesInvoiceTable from './GeneralSalesInvoiceTable';

//Service Imports
import useGeneralSalesInvoiceService from 'services/useGeneralSalesInvoiceService';
import useCustomerService from 'services/useCustomerService';
import { convertDateToLocalReverse } from 'utils/date-operations';
import PaginationComponent from 'ui-component/Pagination/PaginationComponent';
import GeneralSalesInvoiceCard from './GeneralSalesInvoiceCard';
import AlertPopUp from 'ui-component/Alert/AlertPopUp';
import useLocationService from 'services/useLocationService';

const MaterialReceipt = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const { getSalesInvoiceFilteredList, deleteSalesInvoice } = useGeneralSalesInvoiceService();
    const { getCustomer } = useCustomerService();
    const { getLocation } = useLocationService();

    const { userId, userName, role, displayRole, locationId } = useSelector((state) => state.userReducer);

    const pageSize = 50;

    // -----------To set start and end date of current month to the date fields----------
    const currentDate = new Date();
    const startOfMonth = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }, []);
    const endOfMonth = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }, []);
    //States
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSkipValue, setCurrentSkipValue] = useState(0);

    const [fromDate, setFromDate] = useState(convertDateToLocalReverse(startOfMonth));
    const [toDate, setToDate] = useState(convertDateToLocalReverse(endOfMonth));
    const [selectedCustomer, setSelectedCustomer] = useState(-1);

    const [refresh, setRefresh] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [customerList, setCustomerList] = useState();
    const [selectedSalesInvoice, setSelectedSalesInvoice] = useState();
    const [salesInvoiceData, setSalesInvoiceData] = useState();
    const [severity, setSeverity] = useState(undefined);
    const [count, setCount] = useState();
    const [type, setType] = useState(0);
    const [locationList, setLocationList] = useState();
    const [selectedLocation, setSelectedLocation] = useState(-1);

    //API calls
    const gatherSalesInvoiceData = () => {
        let loc = 1;

        getSalesInvoiceFilteredList(searchText, fromDate, toDate, selectedCustomer, loc, type, currentSkipValue, pageSize)
            .then((response) => {
                console.log(searchText, fromDate, toDate, selectedCustomer, currentSkipValue, pageSize);
                console.log(response);
                setSalesInvoiceData(response.data.salesInvoices);
                setCount(response.data.count);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setRefresh(false);
            });
    };

    //Methods
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };
    //UseEffects

    useEffect(() => {
        gatherSalesInvoiceData();
    }, [fromDate, toDate, currentSkipValue]);

    return (
        <>
            <Card>
                <CardHeader title="Material Receipt From Miraj Branch" />
                <Divider sx={{ marginY: 1 }} />
                <CardContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={3} md={4}>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Search by product name"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(event) => {
                                    setSearchText(event.target.value);
                                }}
                                value={searchText}
                            ></TextField>
                        </Grid> */}
                        <Grid item xs={12} sm={3} md={2}>
                            <TextField
                                name="fromDate"
                                size="small"
                                type="date"
                                label="From"
                                value={fromDate}
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <TextField
                                name="toDate"
                                size="small"
                                label="To"
                                type="date"
                                value={toDate}
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            {count > 0 && (
                                <PaginationComponent
                                    count={Math.ceil(count / pageSize)}
                                    onChangeFunction={(event, page) => {
                                        setCurrentSkipValue((page - 1) * pageSize);
                                        setCurrentPage(page);
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                                mt: 2,
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'block'
                                }
                            }}
                        >
                            {Array.isArray(salesInvoiceData) && salesInvoiceData.length > 0 && !refresh ? (
                                <GeneralSalesInvoiceTable
                                    data={salesInvoiceData}
                                    theme={theme}
                                    handleEdit={(item) => {
                                        setSelectedSalesInvoice(item);
                                        setOpenForm(true);
                                    }}
                                    handleDelete={(item) => {
                                        setSelectedSalesInvoice(item);
                                        setOpenDeleteDialog(true);
                                    }}
                                    type={type}
                                />
                            ) : (
                                <>
                                    <>{salesInvoiceData?.length == 0 ? <NoDataFound /> : <Progress />}</>
                                </>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                                mt: 2,
                                display: {
                                    xs: 'block',
                                    sm: 'block',
                                    md: 'block',
                                    lg: 'none'
                                }
                            }}
                        >
                            {Array.isArray(salesInvoiceData) && salesInvoiceData.length > 0 && !refresh ? (
                                <GeneralSalesInvoiceCard
                                    data={salesInvoiceData}
                                    theme={theme}
                                    handleEdit={(item) => {
                                        setSelectedSalesInvoice(item);
                                        setOpenForm(true);
                                    }}
                                    handleDelete={(item) => {
                                        setSelectedSalesInvoice(item);
                                        setOpenDeleteDialog(true);
                                    }}
                                    type={type}
                                />
                            ) : (
                                <>
                                    <>{salesInvoiceData?.length == 0 ? <NoDataFound /> : <Progress />}</>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <AlertPopUp openState={openAlert} onCloseFunction={handleCloseAlert} message={alertTitle} severity={severity} />
        </>
    );
};

export default MaterialReceipt;

// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconShoppingCart,
    IconCurrencyRupee,
    IconCalendarStats,
    IconReport,
    IconTransferOut,
    IconCalendarPlus,
    IconBuildingWarehouse
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconShoppingCart,
    IconCurrencyRupee,
    IconCalendarStats,
    IconReport,
    IconTransferOut,
    IconCalendarPlus,
    IconBuildingWarehouse
};

const adminMenu = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            {
                id: 'masters',
                title: 'Masters',
                type: 'collapse',
                icon: icons.IconReportAnalytics,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-category-master',
                        title: 'Customer Categories',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/customer-category-master',
                        breadcrumbs: false
                    },

                    {
                        id: 'customer',
                        title: 'Customers',
                        type: 'item',
                        // icon: icons.IconUsers,
                        url: '/customer',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'vendor-category-master',
                    //     title: 'Vendor Categories',
                    //     type: 'item',
                    //     // icon: icons.IconReportAnalytics,
                    //     url: '/vendor-category-master',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'vendor',
                    //     title: 'Vendors',
                    //     type: 'item',
                    //     // icon: icons.IconReportAnalytics,
                    //     url: '/vendor',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'product-category',
                    //     title: 'Product Categories',
                    //     type: 'item',
                    //     //icon: icons.IconFileInvoice,
                    //     url: '/product-category',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'product',
                    //     title: 'Products',
                    //     type: 'item',
                    //     // icon: icons.IconReportAnalytics,
                    //     url: '/product',
                    //     breadcrumbs: false
                    // },

                    // {
                    //     id: 'part-category-master',
                    //     title: 'Part Categories',
                    //     type: 'item',
                    //     // icon: icons.IconReportAnalytics,
                    //     url: '/part-category-master',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'part-master',
                    //     title: 'Parts',
                    //     type: 'item',
                    //     // icon: icons.IconReportAnalytics,
                    //     url: '/part-master',
                    //     breadcrumbs: false
                    // },

                    // {
                    //     id: 'service',
                    //     title: 'Services',
                    //     type: 'item',
                    //     url: '/service',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'account',
                    //     title: 'Bank Account',
                    //     type: 'item',
                    //     url: '/account',
                    //     breadcrumbs: false
                    // },

                    {
                        id: 'expenses-category-master',
                        title: 'Expenses Categories',
                        type: 'item',
                        url: '/expenses-category-master',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'bank-accounts',
                    //     title: 'Bank Accounts',
                    //     type: 'item',
                    //     url: '/account',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'employee',
                    //     title: 'Employees',
                    //     type: 'item',
                    //     //icon: icons.IconUsers,
                    //     url: '/employees',
                    //     breadcrumbs: false
                    // }
                ]
            },
            // {
            //     id: 'purchase-records',
            //     title: 'Purchase Records',
            //     type: 'item',
            //     icon: icons.IconShoppingCart,
            //     url: '/purchase-records',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'part-allotment',
            //     icon: icons.IconReportAnalytics,
            //     title: 'Part Allotment',
            //     type: 'item',
            //     url: '/part-allotment',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'quotation',
            //     title: 'Quotation',
            //     type: 'item',
            //     icon: icons.IconNotes,
            //     url: '/quotation',
            //     breadcrumbs: false
            // },
            {
                id: 'transactions',
                title: 'Transactions',
                type: 'collapse',
                icon: icons.IconTransferOut,
                breadcrumbs: false,
                children: [
                    // {
                    //     id: 'purchase-records',
                    //     title: 'Purchase Records',
                    //     type: 'item',
                    //     // icon: icons.IconShoppingCart,
                    //     url: '/purchase-records',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'purchase-record-return',
                    //     //icon: icons.IconShoppingCart,
                    //     title: 'Purchase Returns',
                    //     type: 'item',
                    //     url: '/purchase-record-return',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'transfer-records',
                        //icon: icons.IconTransferOut,
                        title: 'Transfer Records',
                        type: 'item',
                        url: '/transfer-records',
                        breadcrumbs: false
                    },
                    {
                        id: 'sales-invoice',
                        title: 'Sales Invoice',
                        type: 'item',
                        //icon: icons.IconReportMoney,
                        url: '/sales-invoice',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'sales-return',
                    //     //icon: icons.IconReportMoney,
                    //     title: 'Sales Returns',
                    //     type: 'item',
                    //     url: '/sales-return',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'performa-invoice',
                        // icon: icons.IconBrandMastercard,
                        title: 'Proforma Invoice',
                        type: 'item',
                        url: '/performa-invoice',
                        breadcrumbs: false
                    }
                ]
            },
            // {
            //     id: 'material-receipt',
            //     title: 'Material Receipt',
            //     type: 'item',
            //     icon: icons.IconReportMoney,
            //     url: '/material-receipt',
            //     breadcrumbs: false
            // },
            {
                id: 'expenses',
                title: 'Expenses',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/expenses',
                breadcrumbs: false
            },
            {
                id: 'payments',
                title: 'Payments',
                type: 'collapse',
                icon: icons.IconCurrencyRupee,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-payment',
                        title: 'Customer Payments',
                        type: 'item',
                        icon: icons.IconReportMoney,
                        url: '/customer-payment',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'vendor-payment',
                    //     title: 'Vendor Payments',
                    //     type: 'item',
                    //     icon: icons.IconReportMoney,
                    //     url: '/vendor-payment',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'advance',
                        icon: icons.IconBrandMastercard,
                        title: 'Employee Advance',
                        type: 'item',
                        url: '/advance',
                        breadcrumbs: false
                    }
                ]
            },
            // {
            //     id: 'reports',
            //     title: 'Reports',
            //     type: 'item',
            //     icon: icons.IconReportAnalytics,
            //     url: '/reports',
            //     breadcrumbs: false
            // },
            {
                id: 'attendance',
                icon: icons.IconCalendarPlus,
                title: 'Attendance',
                type: 'item',
                url: '/attendance',
                breadcrumbs: false
            },
            {
                id: 'complaints',
                title: 'Complaints',
                type: 'collapse',
                icon: icons.IconCalendarStats,
                breadcrumbs: false,
                children: [
                    {
                        id: 'complaints',
                        //icon: icons.IconCalendarStats,
                        title: 'Complaints',
                        type: 'item',
                        url: '/complaints',
                        breadcrumbs: false
                    },
                    {
                        id: 'complaint-bills',
                        //icon: icons.IconReportMoney,
                        title: 'Complaint Bills',
                        type: 'item',
                        url: '/complaint-bills',
                        breadcrumbs: false
                    },
                    {
                        id: 'complaint-history',
                        //icon: icons.IconNotes,
                        title: 'Complaint History',
                        type: 'item',
                        url: '/complaint-history',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'store-transaction',
                icon: icons.IconBuildingWarehouse,
                title: 'Store',
                type: 'item',
                url: '/store-transaction',
                breadcrumbs: false
            },
            {
                id: 'history',
                title: 'History',
                type: 'collapse',
                icon: icons.IconNotes,
                breadcrumbs: false,
                children: [
                    {
                        id: 'product-history',
                        title: 'Product',
                        type: 'item',
                        //icon: icons.IconReportMoney,
                        url: '/product-history',
                        breadcrumbs: false
                    },
                    {
                        id: 'part-history',
                        title: 'Part',
                        type: 'item',
                        //icon: icons.IconReportMoney,
                        url: '/part-history',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                icon: icons.IconReport,
                url: '/reports',
                breadcrumbs: false
            }
        ]
    }
];

export default adminMenu;

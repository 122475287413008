// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconShoppingCart,
    IconCurrencyRupee,
    IconCalendarStats
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconShoppingCart,
    IconCurrencyRupee,
    IconCalendarStats
};

const accountsMenu = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            // {
            //     id: 'masters',
            //     title: 'Masters',
            //     type: 'collapse',
            //     icon: icons.IconReportAnalytics,
            //     breadcrumbs: false,
            //     // children: [
            //     //     {
            //     //         id: 'customer-category-master',
            //     //         title: 'Customer Categories',
            //     //         type: 'item',
            //     //         // icon: icons.IconReportAnalytics,
            //     //         url: '/customer-category-master',
            //     //         breadcrumbs: false
            //     //     },

            //     //     {
            //     //         id: 'customer',
            //     //         title: 'Customers',
            //     //         type: 'item',
            //     //         // icon: icons.IconUsers,
            //     //         url: '/customer',
            //     //         breadcrumbs: false
            //     //     }
            //     //     // {
            //     //     //     id: 'vendor-category-master',
            //     //     //     title: 'Vendor Categories',
            //     //     //     type: 'item',
            //     //     //     // icon: icons.IconReportAnalytics,
            //     //     //     url: '/vendor-category-master',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'vendor',
            //     //     //     title: 'Vendors',
            //     //     //     type: 'item',
            //     //     //     // icon: icons.IconReportAnalytics,
            //     //     //     url: '/vendor',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'product-category',
            //     //     //     title: 'Product Categories',
            //     //     //     type: 'item',
            //     //     //     //icon: icons.IconFileInvoice,
            //     //     //     url: '/product-category',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'product',
            //     //     //     title: 'Products',
            //     //     //     type: 'item',
            //     //     //     // icon: icons.IconReportAnalytics,
            //     //     //     url: '/product',
            //     //     //     breadcrumbs: false
            //     //     // },

            //     //     // {
            //     //     //     id: 'part-category-master',
            //     //     //     title: 'Part Categories',
            //     //     //     type: 'item',
            //     //     //     // icon: icons.IconReportAnalytics,
            //     //     //     url: '/part-category-master',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'part-master',
            //     //     //     title: 'Parts',
            //     //     //     type: 'item',
            //     //     //     // icon: icons.IconReportAnalytics,
            //     //     //     url: '/part-master',
            //     //     //     breadcrumbs: false
            //     //     // },

            //     //     // {
            //     //     //     id: 'service',
            //     //     //     title: 'Services',
            //     //     //     type: 'item',
            //     //     //     url: '/service',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'account',
            //     //     //     title: 'Bank Account',
            //     //     //     type: 'item',
            //     //     //     url: '/account',
            //     //     //     breadcrumbs: false
            //     //     // },

            //     //     // {
            //     //     //     id: 'expenses-category-master',
            //     //     //     title: 'Expenses Categories',
            //     //     //     type: 'item',
            //     //     //     url: '/expenses-category-master',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'bank-accounts',
            //     //     //     title: 'Bank Accounts',
            //     //     //     type: 'item',
            //     //     //     url: '/account',
            //     //     //     breadcrumbs: false
            //     //     // },
            //     //     // {
            //     //     //     id: 'employee',
            //     //     //     title: 'Employees',
            //     //     //     type: 'item',
            //     //     //     //icon: icons.IconUsers,
            //     //     //     url: '/employees',
            //     //     //     breadcrumbs: false
            //     //     // }
            //     // ]
            // },
            // {
            //     id: 'purchase-records',
            //     title: 'Purchase Records',
            //     type: 'item',
            //     icon: icons.IconShoppingCart,
            //     url: '/purchase-records',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'part-allotment',
            //     icon: icons.IconReportAnalytics,
            //     title: 'Part Allotment',
            //     type: 'item',
            //     url: '/part-allotment',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'quotation',
            //     title: 'Quotation',
            //     type: 'item',
            //     icon: icons.IconNotes,
            //     url: '/quotation',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'sales-invoice',
            //     title: 'Sales Invoice',
            //     type: 'item',
            //     icon: icons.IconReportMoney,
            //     url: '/sales-invoice',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'material-receipt',
            //     title: 'Material Receipt',
            //     type: 'item',
            //     icon: icons.IconReportMoney,
            //     url: '/material-receipt',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'expenses',
            //     title: 'Expenses',
            //     type: 'item',
            //     icon: icons.IconBrandMastercard,
            //     url: '/expenses',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'payments',
            //     title: 'Payments',
            //     type: 'collapse',
            //     icon: icons.IconCurrencyRupee,
            //     breadcrumbs: false,
            //     children: [
            //         {
            //             id: 'customer-payment',
            //             title: 'Customer Payments',
            //             type: 'item',
            //             icon: icons.IconReportMoney,
            //             url: '/customer-payment',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'vendor-payment',
            //             title: 'Vendor Payments',
            //             type: 'item',
            //             icon: icons.IconReportMoney,
            //             url: '/vendor-payment',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'advance',
            //             icon: icons.IconBrandMastercard,
            //             title: 'Employee Advance',
            //             type: 'item',
            //             url: '/advance',
            //             breadcrumbs: false
            //         }
            //     ]
            // },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                icon: icons.IconReportAnalytics,
                url: '/reports',
                breadcrumbs: false
            }
            // {
            //     id: 'attendance',
            //     icon: icons.IconCalendarStats,
            //     title: 'Attendance',
            //     type: 'item',
            //     url: '/attendance',
            //     breadcrumbs: false
            // }
        ]
    }
];

export default accountsMenu;

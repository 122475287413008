import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography, MenuItem, Menu, Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import GetAppTwoToneIcon from '@mui/icons-material/CalendarMonth';
// import FileCopyTwoToneIcon from '@mui/icons-material/';
// import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { DashboardContext } from '.';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    marginTop: 30,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.success.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.success.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TotalIncomeDarkCard = ({ isLoading }) => {
    const theme = useTheme();
    const { partsellData } = useContext(DashboardContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [type, setType] = useState(1);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2 }}>
                        <Grid item align="left">
                            <Typography variant="h4" sx={{ color: 'success.dark', mt: 0.25 }}>
                                Part Sales
                            </Typography>
                        </Grid>
                        <Grid item align="right" mt={-3}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    backgroundColor: theme.palette.grey[100],
                                    color: theme.palette.grey[600],
                                    zIndex: 1
                                }}
                                aria-controls="menu-earning-card"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreHorizIcon fontSize="inherit" />
                            </Avatar>
                            <Menu
                                id="menu-earning-card"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setType(1);
                                        handleClose();
                                    }}
                                >
                                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Month
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setType(2);
                                        handleClose();
                                    }}
                                >
                                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Quarter
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setType(3);
                                        handleClose();
                                    }}
                                >
                                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Year
                                </MenuItem>
                            </Menu>
                        </Grid>
                        {/* <List sx={{ py: 0 }}> */}
                        <Box sx={{ py: 0, display: 'flex', justifyContent: 'space-between' }}>
                            <>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        width: '100%'
                                    }}
                                    primary={
                                        <Typography sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 500, mr: 1 }}>
                                            {type == 1
                                                ? partsellData?.thisMonthCount
                                                : type == 2
                                                ? partsellData?.thisQuarterCount
                                                : type == 3
                                                ? partsellData?.thisFYCount
                                                : 0}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="subtitle2" sx={{ width: '100%', color: 'success.dark', mt: 0.25 }}>
                                            {type == 1
                                                ? 'This Month Count'
                                                : type == 2
                                                ? 'This Quarter Count'
                                                : type == 3
                                                ? 'This Year Count'
                                                : ''}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        width: '100%'
                                    }}
                                    primary={
                                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, ml: 2, textAlign: 'right' }}>
                                            ₹{' '}
                                            {type == 1
                                                ? parseFloat(partsellData?.thisMonthAmount).toLocaleString('en-US', {
                                                      maximumFractionDigits: 2
                                                  })
                                                : type == 2
                                                ? parseFloat(partsellData?.thisQuarterAmount).toLocaleString('en-US', {
                                                      maximumFractionDigits: 2
                                                  })
                                                : type == 3
                                                ? parseFloat(partsellData?.thisFYAmount).toLocaleString('en-US', {
                                                      maximumFractionDigits: 2
                                                  })
                                                : 0}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ textAlign: 'right', width: '100%', color: 'success.dark', mt: 0.25 }}
                                        >
                                            {type == 1
                                                ? 'This Month Amount'
                                                : type == 2
                                                ? 'This Quarter Amount'
                                                : type == 3
                                                ? 'This Year Amount'
                                                : ''}
                                        </Typography>
                                    }
                                />
                            </>
                        </Box>
                        {/* </List> */}
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalIncomeDarkCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalIncomeDarkCard;

import { Alert, Snackbar, Typography, useTheme } from '@mui/material';
import React from 'react';

// ***Method to use***
// <AlertPopUp openState={stateName} onCloseFunction={functionName} message="Message Here"/>

const AlertPopUp = ({ openState, onCloseFunction, message, severity }) => {
    const theme = useTheme();
    //  console.log(severity);
    return (
        <>
            <Snackbar open={openState} autoHideDuration={8000} onClose={onCloseFunction}>
                <Alert
                    onClose={onCloseFunction}
                    severity={severity}
                    sx={{ width: '100%', backgroundColor: severity != 'success' ? theme.palette.error.dark : theme.palette.success.dark }}
                >
                    <Typography variant="h6" color="white">
                        {message}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default AlertPopUp;
